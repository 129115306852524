import Page from "../../components/Page";
import data from "../../data.json";

const About = () => {
    const { description } = data["about"];

    return (
        <Page heading="Conheça-nos">
            {description.map((_, index) => (
                <p className="text-lg" key={`about-${index}`}>
                    {_}
                    <br />
                    <br />
                </p>
            ))}
        </Page>
    );
};

export default About;
