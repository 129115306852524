import Page from "../../components/Page";
import data from "../../data.json";
import Client from "./Client";

const Clients = () => {
    const clients = data["clients"];

    return (
        <Page heading="Clientes">
            <div className="mt-10 flex flex-col gap-10">
                {clients.map(({ link, name, description }, index) => (
                    <Client
                        link={link}
                        name={name}
                        description={description}
                        key={`client-${index}`}
                    />
                ))}
            </div>
        </Page>
    );
};

export default Clients;
