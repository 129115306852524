import Button from "../../components/Button";
import data from "../../data.json";

const CallToAction = () => {
    const { title, description, button } = data["homepage"].callToAction;

    return (
        <div className="w-full lg:w-[1150px] py-10 min-h-[250px] bg-[#002766] mx-auto relative overflow-hidden">
            <div className="container flex flex-col gap-5">
                <h1 className="text-4xl text-white">{title}</h1>

                <p className="text-xl text-white max-w-3xl z-10">
                    {description}
                </p>

                <Button
                    label={button.label}
                    variant="secondary"
                    link={button.link}
                />
            </div>

            <img
                src="/assets/call-to-action.png"
                alt=""
                className="absolute top-0 right-0 w-80"
            />
        </div>
    );
};

export default CallToAction;
