import { FC, ReactNode, useEffect } from "react";
import data from "../data.json";

interface PageProps {
    heading: string;
    children: ReactNode;
}
const Page: FC<PageProps> = ({ heading, children }) => {
    const { titleSuffix } = data["general"];

    useEffect(() => {
        document.title = `${heading} ${titleSuffix}`;
    }, []);

    return (
        <div className="lg:min-w-[1200px] w-full relative">
            <div className="container py-10">
                <h1 className="text-3xl text-primary font-bold mb-5">
                    {heading}
                </h1>

                {children}
            </div>

            <img
                src="/assets/page.png"
                alt=""
                className="absolute top-0 right-32 -z-10"
            />
        </div>
    );
};

export default Page;
