import React, { FC, ReactNode } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./index.css";
import About from "./pages/About";
import Clients from "./pages/Clients";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Services from "./pages/Services";

const WithHeaderAndFooter: FC<{
    children: ReactNode;
}> = ({ children }) => {
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    );
};

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <WithHeaderAndFooter>
                <Home />
            </WithHeaderAndFooter>
        ),
    },
    {
        path: "/about",
        element: (
            <WithHeaderAndFooter>
                <About />
            </WithHeaderAndFooter>
        ),
    },
    {
        path: "/services",
        element: (
            <WithHeaderAndFooter>
                <Services />
            </WithHeaderAndFooter>
        ),
    },
    {
        path: "/clients",
        element: (
            <WithHeaderAndFooter>
                <Clients />
            </WithHeaderAndFooter>
        ),
    },
    {
        path: "/contact",
        element: (
            <WithHeaderAndFooter>
                <Contact />
            </WithHeaderAndFooter>
        ),
    },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
