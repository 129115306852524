import { FC } from "react";
import { HiBuildingOffice2 } from "react-icons/hi2";

interface ClientCardProps {
    link: string;
    name: string;
}
const ClientCard: FC<ClientCardProps> = ({ link, name, ...restProps }) => {
    return (
        <a href={link} className="flex gap-5 items-center" {...restProps}>
            <div className="p-2 rounded-xl bg-primary">
                <HiBuildingOffice2 className="text-white text-2xl" />
            </div>
            <p className="text-primary text-lg lg:text-base">{name}</p>
        </a>
    );
};

export default ClientCard;
