import { FC } from "react";
import { HiBuildingOffice2 } from "react-icons/hi2";

interface ClientProps {
    link: string;
    name: string;
    description: string;
}
const Client: FC<ClientProps> = ({ link, name, description }) => {
    return (
        <a href={link} target="_blank" className="w-full flex flex-col gap-5">
            <div className="flex items-center gap-5">
                <div className="p-2 rounded-xl bg-primary">
                    <HiBuildingOffice2 className="text-white text-2xl" />
                </div>
                <p className="text-lg text-primary">{name}</p>
            </div>
            <p className="">{description}</p>
        </a>
    );
};

export default Client;
