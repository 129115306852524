import { FC } from "react";
import ReactCardFlip from "react-card-flip";
import { BsFillMouseFill } from "react-icons/bs";

interface ServiceCardProps {
    service: {
        name: string;
        description: string;
        imageAssetName: string;
    };
    index?: number;
    flippedIndex: number | null;
    setFlippedIndex: any;
}
const ServiceCard: FC<ServiceCardProps> = ({
    service: { name, description, imageAssetName },
    index,
    flippedIndex,
    setFlippedIndex,
    ...restProps
}) => {
    const isFlipped = flippedIndex === index;

    const toggleFlip = () => {
        if (flippedIndex === index) {
            setFlippedIndex(null);
        } else {
            setFlippedIndex(index);
        }
    };

    return (
        <div
            className="group w-2/3 lg:w-80 h-96 lg:h-96 [perspective:1000px] cursor-pointer"
            {...restProps}
        >
            <ReactCardFlip
                isFlipped={isFlipped}
                containerClassName="w-full h-full"
            >
                <div onClick={toggleFlip} className="w-full h-full relative">
                    <img
                        className="w-full h-full object-cover shadow-xl"
                        src={`/assets/${imageAssetName}`}
                        alt=""
                        draggable="false"
                    />
                    <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full px-5 py-3 flex justify-between items-center bg-primary-dark text-white">
                        {name}

                        <BsFillMouseFill className="text-xl" />
                    </div>
                </div>
                <div
                    onClick={toggleFlip}
                    className="w-full h-full bg-primary-dark px-12 text-center text-white"
                >
                    <div className="flex min-h-full overflow-y-auto flex-col items-center justify-center gap-5">
                        <h1 className="text-3xl font-bold">{name}</h1>
                        <p className="text-base text-ellipsis">{description}</p>
                    </div>
                </div>
            </ReactCardFlip>
            {/* <div
                onClick={(e) => {
                    e.currentTarget.classList.toggle(
                        "[transform:rotateY(180deg)]"
                    );
                }}
                className="relative h-full w-full shadow-xl transition-all duration-500 [transform-style:preserve-3d]"
            >
                <div className="absolute inset-0 h-full w-full">
                    <img
                        className="absolute inset-0 h-full w-full object-cover shadow-xl"
                        src={`/assets/${imageAssetName}`}
                        alt=""
                        draggable="false"
                    />
                    <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full px-5 py-3 flex justify-between items-center bg-primary-dark text-white">
                        {name}

                        <BsFillMouseFill className="text-xl" />
                    </div>
                </div>
                <div className="absolute inset-0 h-full w-full bg-primary-dark px-12 text-center text-white [transform:rotateY(180deg)] [backface-visibility:hidden]">
                    <div className="flex min-h-full flex-col items-center justify-center gap-5">
                        <h1 className="text-3xl font-bold">{name}</h1>
                        <p className="text-base text-ellipsis">{description}</p>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default ServiceCard;
