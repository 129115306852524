import Button from "../../components/Button";
import Input from "../../components/Input";
import Page from "../../components/Page";
import TextArea from "../../components/TextArea";
import data from "../../data.json";

const Contact = () => {
    const { phoneNumber, emailAddress, address } = data["general"].contact;

    return (
        <Page heading="Fale conosco">
            <div className="flex flex-col gap-5">
                <p className="text-lg">
                    Estamos prontos para lhe atender, como podemos ajudar?
                </p>

                <div className="flex gap-40 flex-wrap justify-center">
                    <form
                        action="https://formkeep.com/f/14091bb5ef17"
                        acceptCharset="UTF-8"
                        encType="multipart/form-data"
                        method="POST"
                        className="w-full lg:w-full lg:flex-1 flex flex-col gap-5 text-lg lg:text-base"
                    >
                        <div className="flex flex-col gap-2">
                            <label htmlFor="input#1">
                                Nome
                                <span className="text-[#ff0f0f] font-bold">
                                    *
                                </span>
                            </label>
                            <Input
                                id="input#1"
                                type="text"
                                name="nome"
                                required={true}
                            />
                        </div>
                        <div className="flex gap-5">
                            <div className="flex-1 flex flex-col gap-2">
                                <label htmlFor="input#2">
                                    E-mail
                                    <span className="text-[#ff0f0f] font-bold">
                                        *
                                    </span>
                                </label>
                                <Input
                                    id="input#2"
                                    type="email"
                                    name="email"
                                    required={true}
                                />
                            </div>
                            <div className="flex-1 flex flex-col gap-2">
                                <label htmlFor="input#3">Telefone</label>
                                <Input
                                    id="input#3"
                                    type="text"
                                    name="telefone"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="input#4">
                                Mensagem:{" "}
                                <span className="text-[#ff0f0f] font-bold">
                                    *
                                </span>
                            </label>
                            <TextArea
                                id="input#4"
                                type="text"
                                name="mensagem"
                                required={true}
                            />
                        </div>
                        <p>
                            <span className="text-[#ff0f0f] font-bold">*</span>{" "}
                            campos obrigatórios
                        </p>

                        <Button
                            type="submit"
                            label="ENVIAR"
                            variant="primary"
                        />
                    </form>
                    <div className="w-full lg:w-full lg:flex-1 flex flex-col gap-5">
                        <p className="text-2xl text-primary-dark font-medium">
                            Ligue para nós
                        </p>

                        <div className="w-full p-5 border border-1 border-black">
                            <p className="text-lg lg:text-base">
                                Fone / Fax:{" "}
                                <span className="text-primary font-bold">
                                    {phoneNumber.countryCode}{" "}
                                    {phoneNumber.number}
                                </span>
                            </p>
                            <p className="font-bold">{emailAddress}</p>
                        </div>

                        <p className="text-2xl text-primary-dark font-medium">
                            Faça nos uma visita:
                        </p>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3819.8661564763806!2d-49.2849722!3d-16.7833333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfc232bf986ea8e75!2zMTbCsDQ3JzAwLjAiUyA0OcKwMTcnMDUuOSJX!5e0!3m2!1spt-BR!2srs!4v1674955229982!5m2!1spt-BR!2srs"
                            className="w-full h-[600px] lg:h-[300px]"
                        />
                        <p className="break-words text-lg lg:text-base">
                            {address.line1} {address.line2} <br />{" "}
                            {address.line3}
                        </p>
                        <a
                            href="https://www.google.com.br/maps/place/16%C2%B047'00.0%22S+49%C2%B017'05.9%22W/@-16.7833333,-49.2849722,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xfc232bf986ea8e75!8m2!3d-16.7833333!4d-49.2849722?hl=pt-BR"
                            className="underline text-lg lg:text-base"
                        >
                            Ampliar mapa de localizaçao
                        </a>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default Contact;
