import { useEffect } from "react";
import data from "../../data.json";
import Banner from "./Banner";
import CallToAction from "./CallToAction";
import OurClients from "./OurClients";
import Services from "./Services";

const Home = () => {
    const { siteTitle } = data["general"];
    useEffect(() => {
        document.title = siteTitle;
    }, []);

    return (
        <>
            <Banner />
            <CallToAction />
            <Services />
            <OurClients />
        </>
    );
};

export default Home;
