import { useState } from "react";
import Button from "../../components/Button";
import ServiceCard from "../../components/ServiceCard";
import data from "../../data.json";

const Services = () => {
    const services = data["services"];

    const [flippedIndex, setFlippedIndex] = useState(null);

    return (
        <div className="w-full py-10">
            <div className="container flex flex-col gap-5">
                <a href="/services" className="text-3xl font-bold text-primary">
                    NOSSOS SERVIÇOS
                </a>

                <div className="grid grid-cols-2 place-items-center gap-5">
                    {services.slice(-4).map((service, index) => (
                        <ServiceCard
                            service={service}
                            index={index}
                            flippedIndex={flippedIndex}
                            setFlippedIndex={setFlippedIndex}
                            key={`service-${index}`}
                        />
                    ))}
                </div>

                {services.length > 4 && (
                    <div className="flex items-center justify-center mt-10">
                        <Button label="Veja mais" link="/services" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Services;
