import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import data from "../../data.json";

const Banner = () => {
    const { bannerImageDelay } = data["general"];
    const banners = data["homepage"].banners;
    const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

    const nextBanner = (e?: any) => {
        e?.preventDefault();
        setCurrentBannerIndex(
            (currentBannerIndex) => (currentBannerIndex + 1) % banners.length
        );
    };

    const prevBanner = (e?: any) => {
        e?.preventDefault();
        if (currentBannerIndex - 1 < 0) {
            setCurrentBannerIndex(banners.length - 1);
        } else {
            setCurrentBannerIndex(
                (currentBannerIndex) => currentBannerIndex - 1
            );
        }
    };

    useEffect(() => {
        const _ = setInterval(() => {
            nextBanner();
        }, bannerImageDelay * 1000);

        return () => {
            clearInterval(_);
        };
    }, []);

    return (
        <div className="w-full lg:w-[1100px] mx-auto flex items-center justify-center lg:py-10">
            <div
                onClickCapture={nextBanner}
                className="py-40 hidden lg:flex items-center px-5 cursor-pointer"
            >
                <HiChevronLeft className="text-4xl" />
            </div>
            <a
                href={banners[currentBannerIndex].link}
                className="w-full select-none"
            >
                <motion.img
                    src={`/assets${banners[currentBannerIndex].imageAssetName}`}
                    alt=""
                    draggable="false"
                    className="w-full lg:w-[1100px]"
                />
            </a>
            <div
                onClickCapture={prevBanner}
                className="py-40 hidden lg:flex items-center px-5 cursor-pointer"
            >
                <HiChevronRight className="text-4xl" />
            </div>
        </div>
    );
};

export default Banner;
