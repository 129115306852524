import { AllHTMLAttributes, FC } from "react";

interface TextareaProps extends AllHTMLAttributes<HTMLTextAreaElement> {}
const TextArea: FC<TextareaProps> = ({ ...restProps }) => {
    return (
        <textarea
            className="w-full h-28 px-3 py-2 border-1 border-black"
            {...restProps}
        />
    );
};

export default TextArea;
