import { HiHome, HiMail, HiPhone } from "react-icons/hi";
import { Link } from "react-router-dom";
import data from "../data.json";

const Header = () => {
    const { phoneNumber, emailAddress } = data["general"].contact;
    const headerData = data["header"];

    return (
        <header>
            <div className="w-full flex items-center">
                <div className="container">
                    <div className="h-[115px] flex justify-between items-center">
                        <Link to="/">
                            <img
                                src={`/assets/${headerData.logoAssetName}`}
                                alt=""
                                className="h-[80px] lg:h-auto"
                            />
                        </Link>

                        <div className="flex gap-5 items-center">
                            <div className="flex gap-1 items-center">
                                <div className="px-2 flex items-center text-primary text-2xl">
                                    <HiPhone />
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-primary text-sm uppercase">
                                        FALE CONOSCO
                                    </p>
                                    <p className="text-primary text-xl font-bold">
                                        <span className="text-xs mr-1 font-normal">
                                            {phoneNumber.countryCode}
                                        </span>
                                        {phoneNumber.number}
                                    </p>
                                </div>
                            </div>
                            <div className="flex gap-1 items-center">
                                <div className="px-2 flex items-center text-primary text-2xl">
                                    <HiMail />
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-primary text-sm uppercase">
                                        SOLICITE UMA VISITA
                                    </p>
                                    <p className="text-sm font-medium">
                                        {emailAddress}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="navigation w-full h-20">
                <div className="h-full container flex items-center gap-5 text-white text-xl">
                    <a
                        href="/"
                        className="h-full px-4 flex items-center justify-center bg-primary-dark"
                    >
                        <HiHome className="text-white text-2xl" />
                    </a>
                    {headerData.navigation.map(({ link, label }, index) => (
                        <Link
                            to={link}
                            key={`header-link-${index}`}
                            className={`h-full px-4 flex items-center underline-offset-8 hover:underline ${
                                window.location.pathname === link
                                    ? "underline"
                                    : ""
                            } text-base uppercase`}
                        >
                            {label}
                        </Link>
                    ))}
                </div>
            </div>
        </header>
    );
};

export default Header;
