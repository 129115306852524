import { AllHTMLAttributes, FC } from "react";

interface InputProps extends AllHTMLAttributes<HTMLInputElement> {}
const Input: FC<InputProps> = ({ ...restProps }) => {
    return (
        <input
            className="w-full px-3 py-2 border-1 border-black"
            {...restProps}
        />
    );
};

export default Input;
