import Button from "../../components/Button";
import ClientCard from "../../components/ClientCard";
import data from "../../data.json";

const OurClients = () => {
    const clients = data["clients"];

    return (
        <div className="w-full py-20 relative">
            <div className="container flex flex-col items-start justify-center gap-5">
                <p className="text-3xl font-bold text-primary">
                    NOSSOS CLIENTES
                </p>
                <p className="text-xl">
                    Entregando serviços com qualidade e eficiência, a RB Moreira Engenharia vem
                    conquistando cada vez mais clientes satisfeitos:
                </p>

                <div className="w-full flex items-center justify-start lg:justify-between gap-5 my-5">
                    {clients.slice(-3).map(({ link, name }, index) => (
                        <ClientCard
                            link={link}
                            name={name}
                            key={`clients-${index}`}
                        />
                    ))}
                </div>

                <Button link="/clients" label="Confira mais clientes" />
            </div>
            <img
                src="/assets/before-footer.png"
                alt=""
                className="absolute bottom-0 left-1/2 -translate-x-1/2 -z-10"
            />
        </div>
    );
};

export default OurClients;
