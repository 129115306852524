import { useState } from "react";
import Page from "../../components/Page";
import ServiceCard from "../../components/ServiceCard";
import data from "../../data.json";

const Services = () => {
    const services = data["services"];

    const [flippedIndex, setFlippedIndex] = useState(null);

    return (
        <Page heading="Serviços">
            <>
                <div className="grid grid-cols-2 place-items-center gap-5">
                    {services.map((service, index) => (
                        <ServiceCard
                            service={service}
                            index={index}
                            flippedIndex={flippedIndex}
                            setFlippedIndex={setFlippedIndex}
                            key={`service-${index}`}
                        />
                    ))}
                </div>
            </>
        </Page>
    );
};

export default Services;
