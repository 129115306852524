import { FC, ReactNode } from "react";
import { AiFillInstagram } from "react-icons/ai";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import data from "../data.json";

const Footer = () => {
    const { phoneNumber, instagram, emailAddress, address } =
        data["general"].contact;
    const footerData = data["footer"];

    return (
        <footer className="w-full py-12 border-t-2 border-primary">
            <div className="container grid grid-cols-12 gap-5 place-items-baseline">
                <div className="col-span-4 flex flex-col gap-2 overflow-hidden">
                    <img src="/assets/logo-footer.png" alt="" width={180} />
                    <p className="text-sm mt-8 break-words">
                        {footerData.about}
                    </p>
                    <a
                        href="/about"
                        className="text-sm text-primary font-medium mt-3 hover:underline"
                    >
                        Saiba mais
                    </a>
                </div>
                <div className="w-full col-span-8 flex gap-5 justify-between">
                    <Section label="ONDE ESTAMOS">
                        <>
                            <p>
                                {address.line1} <br /> {address.line2} <br />{" "}
                                {address.line3}
                            </p>

                            <a
                                href="/contact"
                                className="text-sm text-primary font-medium hover:underline mt-3"
                            >
                                Ver mapa de localização
                            </a>
                        </>
                    </Section>
                    <Section label="NAVEGUE">
                        <ul className="flex flex-col">
                            {footerData.navigation.map(
                                ({ link, label }, index) => (
                                    <Link
                                        to={link}
                                        key={`footer-link-${index}`}
                                        className={`flex items-center gap-1 text-lg lg:text-base ${
                                            window.location.pathname === link
                                                ? "font-bold"
                                                : ""
                                        } transition-all`}
                                    >
                                        {window.location.pathname === link && (
                                            <HiChevronRight className="text-xl" />
                                        )}
                                        {label}
                                    </Link>
                                )
                            )}
                        </ul>
                    </Section>
                    <Section label="ATENDIMENTO">
                        <div className="w-full flex flex-col">
                            <p className="font-medium">
                                <span className="text-primary font-bold">
                                    Telefone:
                                </span>{" "}
                                {phoneNumber.countryCode} {phoneNumber.number}
                            </p>
                            <p className="text-primary font-bold max-w-[20ch] break-words lg:whitespace-nowrap">
                                {emailAddress}
                            </p>

                            <a
                                href={`https://www.instagram.com/${instagram}`}
                                target="_blank"
                                className="flex items-center gap-2 p-2"
                            >
                                <AiFillInstagram className="text-2xl text-primary-dark" />
                                <div className="h-full border-l-2 outline-primary pl-2 flex flex-col">
                                    <p className="text-lg">@{instagram}</p>
                                    <p className="text-xs">
                                        Siga-nos no Instagram
                                    </p>
                                </div>
                            </a>
                        </div>
                    </Section>
                </div>
            </div>
        </footer>
    );
};

interface SectionProps {
    label: string;
    children: ReactNode;
}
const Section: FC<SectionProps> = ({ label, children }) => {
    return (
        <div className="flex flex-col gap-2">
            <p className="text-lg my-6 font-medium">{label}</p>
            {children}
        </div>
    );
};

export default Footer;
