import { AllHTMLAttributes, FC } from "react";
import { Link } from "react-router-dom";

interface ButtonProps extends AllHTMLAttributes<HTMLButtonElement> {
    link?: string;
    variant?: "primary" | "secondary";
    label: string;
}
const Button: FC<ButtonProps> = ({ link, variant = "primary", label }) => {
    if (link) {
        return (
            <Link
                to={link}
                className={`group w-min px-5 py-3 ${
                    variant === "primary"
                        ? "bg-white hover:bg-primary-dark border-primary-dark"
                        : "hover:bg-white border-white"
                } border whitespace-nowrap transition-all`}
            >
                <p
                    className={`font-bold ${
                        variant === "primary"
                            ? "text-primary-dark group-hover:text-white"
                            : "text-white group-hover:text-primary-dark"
                    }`}
                >
                    {label}
                </p>
            </Link>
        );
    }

    return (
        <button
            className={`group w-min px-5 py-3 ${
                variant === "primary"
                    ? "hover:bg-primary-dark border-primary-dark"
                    : "hover:bg-white border-white"
            } border whitespace-nowrap transition-all`}
        >
            <p
                className={`font-bold ${
                    variant === "primary"
                        ? "text-primary-dark group-hover:text-white"
                        : "text-white group-hover:text-primary-dark"
                }`}
            >
                {label}
            </p>
        </button>
    );
};

export default Button;
